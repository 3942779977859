import React, { Component } from "react"
import { connect } from "react-redux"
import { window, document } from "browser-monads"
import GoTrue from "gotrue-js"
import get from "lodash/get"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import PageHero from "../components/userPanel"
import Container from "./../components/container"
import { navigate } from "gatsby"
import Icon from "react-icons-kit"
import { sun } from "react-icons-kit/icomoon/sun"
import { money } from "react-icons-kit/fa/money"
import { envelopeO } from "react-icons-kit/fa/envelopeO"
import { ic_local_car_wash } from "react-icons-kit/md/ic_local_car_wash"
import { calendar } from "react-icons-kit/icomoon/calendar"
import { ic_airline_seat_recline_extra } from "react-icons-kit/md/ic_airline_seat_recline_extra"
import { user } from "react-icons-kit/metrize/user"
import { ic_lightbulb_outline } from "react-icons-kit/md/ic_lightbulb_outline"
import { ic_phone_in_talk } from "react-icons-kit/md/ic_phone_in_talk"
import { pencil } from "react-icons-kit/fa/pencil"
import { cloud } from "react-icons-kit/iconic/cloud"
import { users } from "react-icons-kit/fa/users"
import { Loader } from "./../components/dashboard/loading"
import { book } from "react-icons-kit/icomoon/book"
import Popup from "reactjs-popup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimesCircle,
  faPen,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons"
import { toggleTeamEditLoad } from "./../actions/loaders/toggleTeamEditLoad"
import { toggleTeamMemberEditLoad } from "./../actions/loaders/toggleTeamMemberEditLoad"
import styles from "./../components/RegisterForm/registerForm.module.css"

const netlifyIdentity = require("netlify-identity-widget")
const NETLIFY_FUNC = "farajaraftingchallenge.org/.netlify/functions"
const LOCAL_FUNC = "localhost:9000"

const auth = new GoTrue({
  APIUrl: "https://farajaraftingchallenge.org/.netlify/identity",
  audience: "",
  setCookie: false
})

const API_URL = process.env.NODE_ENV === "development" ? `http://${LOCAL_FUNC}` : `https://${NETLIFY_FUNC}`;

class TeamPage extends Component {
  constructor(props) {
    super(props)
    this.modalRef = React.createRef()
    this.state = {
      user: null,
      teamData: null,
      members: null,
      isLoading: true,
      teammate: "",
      discardedIndex: null,
      isModalDisabled: false,
      slots: {
        friday: 11,
        fridayMorning: 11,
        fridayAfternoon: 11,
        saturdayMorning: 11,
        saturdayAfternoon: 11,
        sunday: 11
      }
    }
  }
  componentDidMount() {
    const currentUser = auth.currentUser()
    if (currentUser) {
      this.setState({ user: currentUser })
      this.navigateUser(currentUser)
    } else {
      navigate("/register-new/")
    }
  }
  navigateUser = user => {
    let shouldNavToAdmin = false
    Object.keys(user.app_metadata).forEach(item => {
      if (item === "roles") {
        user.app_metadata.roles.find(role => {
          if (role === "admin") {
            shouldNavToAdmin = true
            return
          }
        })
      }
    })
    if (shouldNavToAdmin) {
      //not team leader
      navigate("/admin/")
      return
    } else {
      this.getTeam(user.id)
      fetch(`${API_URL}/queryForSlots`)
        .then(res => res.json())
        .then(data => {
          console.log("data:", data)
          this.setState(
            {
              slots: {
                friday: parseFloat(data.data[1][0]),
                fridayMorning: parseFloat(data.data[1][1]),
                fridayAfternoon: parseFloat(data.data[1][2]),
                saturdayMorning: parseFloat(data.data[1][3]),
                saturdayAfternoon: parseFloat(data.data[1][4]),
                sunday: parseFloat(data.data[1][5])
              }
            })
            console.log("Slots: ", this.state.slots)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  getTeam = id => {
    fetch(
      `${API_URL}/queryForTeam?teamId=${id}&shouldRetrieve=true`
    )
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            const teamData = data.data.map(rows => {
              return rows.reduce(
                (acc, item, ind) => {
                  switch (ind) {
                    case 1:
                      return { ...acc, teamName: item }
                    case 2:
                      return { ...acc, organisation: item }
                    case 3:
                      return { ...acc, targetAmount: item }
                    case 4:
                      return { ...acc, raftingDay: item }
                    case 5:
                      return { ...acc, raftingTime: item }
                    case 6:
                      return { ...acc, firstName: item }
                    case 7:
                      return { ...acc, lastName: item }
                    case 8:
                      return { ...acc, emailAddress: item }
                    case 9:
                      return { ...acc, mobileNumber: item }
                    case 10:
                      return { ...acc, shirtSize: item }
                    case 11:
                      return { ...acc, transport: item }
                    case 12:
                      return { ...acc, approved: item }
                    case 13:
                      return { ...acc, isTeamLeader: item }
                    case 14:
                      return { ...acc, memberId: item }
                    case 15:
                      return { ...acc, createdAt: item }
                    case 16:
                      return { ...acc, editable: item }
                    case 17:
                      return { ...acc, teamEditable: item }
                    default:
                      return acc
                  }
                },
                {
                  teamName: "",
                  organisation: "",
                  targetAmount: "",
                  raftingDay: "",
                  raftingTime: "",
                  firstName: "",
                  lastName: "",
                  emailAddress: "",
                  mobileNumber: "",
                  shirtSize: "",
                  transport: "",
                  approved: "",
                  isTeamLeader: "",
                  memberId: "",
                  createdAt: "",
                  editable: "",
                  teamEditable: ""
                }
              )
            })
            if (teamData !== undefined && teamData !== null) {
              this.setState({
                teamData: teamData,
                members: teamData.reduce((acc, item, index) => {
                  acc[index] = `${item["firstName"]} ${item["lastName"]}`
                  return acc
                }, []),
                isLoading: false,
                teammate: ""
              })
            } else {
              navigate("/createTeam/")
            }
          })
        } else {
          response.json().then(error => {
            console.log(
              `Request failed - Status code ${response.status}\nError:`,
              error
            )
            navigate("/createTeam/")
          })
        }
      })
      .catch(err => console.log(`Fetch error: ${err}`))
  }

  handleBuildTeamMemberTags = () => {
    return this.state.members.map(
      (item, index) =>
        item.length > 0 && (
          <div
            key={index}
            className="badge mr-3 mt-2"
            style={{ backgroundColor: "#E4419E", color: "#fff", padding: 8 }}
          >
            <span style={{ fontSize: 20 }}>{item}</span>
          </div>
        )
    )
  }

  handleBuildTeamMemberTagsWithDiscard = () => {
    return this.state.members.map((item, index) => {
      return (
        item.length > 0 && (
          <div
            key={index}
            className="badge mr-3 mt-2"
            style={{
              backgroundColor:
                this.state.teamData[index].editable.toLowerCase() === "yes"
                  ? "#E4419E"
                  : "gray",
              cursor:
                this.state.teamData[index].editable.toLowerCase() === "yes"
                  ? "pointer"
                  : "help",
              color: "#fff",
              padding: 8
            }}
          >
            <span style={{ fontSize: 20 }}>{item}</span>
            <Popup
              trigger={
                this.state.teamData[index].editable.toLowerCase() === "yes" ? (
                  <FontAwesomeIcon
                    icon={faPen}
                    className="ml-2"
                    style={{ marginBottom: 2 }}
                  ></FontAwesomeIcon>
                ) : (
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="ml-2"
                    style={{ marginBottom: 2 }}
                  ></FontAwesomeIcon>
                )
              }
              modal
              closeOnDocumentClick
              disabled={this.state.isModalDisabled}
            >
              <form name="teams" className="mt-3" method="POST">
                <h2
                  style={{
                    textAlign: "center",
                    color:
                      this.state.teamData[index].editable.toLowerCase() ===
                      "yes"
                        ? "#000"
                        : "#E4419E",
                    marginBottom: 25
                  }}
                >
                  {this.state.teamData[index].editable.toLowerCase() === "yes"
                    ? `Update ${item}'s Info`
                    : `Can't Edit ${item}'s Info Again`}
                </h2>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <label
                    style={{
                      flex: 3,
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 17
                    }}
                  >
                    <strong>First Name</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="firstName"
                    value={this.state.teamData[index].firstName}
                    onChange={char => {
                      if (
                        this.state.teamData[index].editable.toLowerCase() ===
                        "yes"
                      ) {
                        const newStr = {
                          ...this.state.teamData[index],
                          firstName: char.target.value
                        }
                        const newTeamData = {
                          ...this.state.teamData,
                          [index]: newStr
                        }
                        const newState = {
                          ...this.state,
                          teamData: newTeamData
                        }
                        this.setState(newState)
                      }
                    }}
                    maxLength={30}
                    style={{ flex: 6 }}
                  ></input>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <label
                    style={{
                      flex: 3,
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 17
                    }}
                  >
                    <strong>Last Name</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="lastName"
                    value={this.state.teamData[index].lastName}
                    onChange={char => {
                      if (
                        this.state.teamData[index].editable.toLowerCase() ===
                        "yes"
                      ) {
                        const newStr = {
                          ...this.state.teamData[index],
                          lastName: char.target.value
                        }
                        const newTeamData = {
                          ...this.state.teamData,
                          [index]: newStr
                        }
                        const newState = {
                          ...this.state,
                          teamData: newTeamData
                        }
                        this.setState(newState)
                      }
                    }}
                    maxLength={30}
                    style={{ flex: 6 }}
                  ></input>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <label
                    style={{
                      flex: 3,
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 17
                    }}
                  >
                    <strong>Mobile Number</strong>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="mobileNumber"
                    value={this.state.teamData[index].mobileNumber}
                    onChange={num => {
                      if (
                        this.state.teamData[index].editable.toLowerCase() ===
                        "yes"
                      ) {
                        const newStr = {
                          ...this.state.teamData[index],
                          mobileNumber: num.target.value
                        }
                        const newTeamData = {
                          ...this.state.teamData,
                          [index]: newStr
                        }
                        const newState = {
                          ...this.state,
                          teamData: newTeamData
                        }
                        this.setState(newState)
                      }
                    }}
                    maxLength={30}
                    style={{ flex: 6 }}
                  ></input>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <label
                    style={{
                      flex: 3,
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 17
                    }}
                  >
                    <strong>Email Address</strong>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    name="emailAddress"
                    value={this.state.teamData[index].emailAddress}
                    onChange={char => {
                      if (
                        this.state.teamData[index].editable.toLowerCase() ===
                        "yes"
                      ) {
                        const newStr = {
                          ...this.state.teamData[index],
                          emailAddress: char.target.value
                        }
                        const newTeamData = {
                          ...this.state.teamData,
                          [index]: newStr
                        }
                        const newState = {
                          ...this.state,
                          teamData: newTeamData
                        }
                        this.setState(newState)
                      }
                    }}
                    maxLength={30}
                    style={{ flex: 6 }}
                  ></input>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <label
                    style={{
                      flex: 3,
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 17
                    }}
                  >
                    <strong>Shirt Size</strong>
                  </label>

                  <div className="col text-center">
                    <label
                      style={{
                        textAlign: "left",
                        marginTop: 5,
                        color: "#000",
                        fontSize: 15
                      }}
                    >
                      S
                    </label>
                    <input
                      type="radio"
                      className="form-control"
                      placeholder=""
                      value={"S"}
                      checked={this.state.teamData[index].shirtSize === "S"}
                      onChange={opt => {
                        if (
                          this.state.teamData[index].editable.toLowerCase() ===
                          "yes"
                        ) {
                          const newStr = {
                            ...this.state.teamData[index],
                            shirtSize: opt.target.value
                          }
                          const newTeamData = {
                            ...this.state.teamData,
                            [index]: newStr
                          }
                          const newState = {
                            ...this.state,
                            teamData: newTeamData
                          }
                          this.setState(newState)
                        }
                      }}
                    />
                  </div>

                  <div className="col text-center">
                    <label
                      style={{
                        textAlign: "left",
                        marginTop: 5,
                        color: "#000",
                        fontSize: 15
                      }}
                    >
                      M
                    </label>
                    <input
                      type="radio"
                      className="form-control"
                      placeholder=""
                      value={"M"}
                      checked={this.state.teamData[index].shirtSize === "M"}
                      onChange={opt => {
                        if (
                          this.state.teamData[index].editable.toLowerCase() ===
                          "yes"
                        ) {
                          const newStr = {
                            ...this.state.teamData[index],
                            shirtSize: opt.target.value
                          }
                          const newTeamData = {
                            ...this.state.teamData,
                            [index]: newStr
                          }
                          const newState = {
                            ...this.state,
                            teamData: newTeamData
                          }
                          this.setState(newState)
                        }
                      }}
                    />
                  </div>

                  <div className="col text-center">
                    <label
                      style={{
                        textAlign: "left",
                        marginTop: 5,
                        color: "#000",
                        fontSize: 15
                      }}
                    >
                      L
                    </label>
                    <input
                      type="radio"
                      className="form-control"
                      placeholder=""
                      value={"L"}
                      checked={this.state.teamData[index].shirtSize === "L"}
                      onChange={opt => {
                        if (
                          this.state.teamData[index].editable.toLowerCase() ===
                          "yes"
                        ) {
                          const newStr = {
                            ...this.state.teamData[index],
                            shirtSize: opt.target.value
                          }
                          const newTeamData = {
                            ...this.state.teamData,
                            [index]: newStr
                          }
                          const newState = {
                            ...this.state,
                            teamData: newTeamData
                          }
                          this.setState(newState)
                        }
                      }}
                    />
                  </div>

                  <div className="col text-center">
                    <label
                      style={{
                        textAlign: "left",
                        marginTop: 5,
                        color: "#000",
                        fontSize: 15
                      }}
                    >
                      XL
                    </label>
                    <input
                      type="radio"
                      className="form-control"
                      placeholder=""
                      value={"XL"}
                      checked={this.state.teamData[index].shirtSize === "XL"}
                      onChange={opt => {
                        if (
                          this.state.teamData[index].editable.toLowerCase() ===
                          "yes"
                        ) {
                          const newStr = {
                            ...this.state.teamData[index],
                            shirtSize: opt.target.value
                          }
                          const newTeamData = {
                            ...this.state.teamData,
                            [index]: newStr
                          }
                          const newState = {
                            ...this.state,
                            teamData: newTeamData
                          }
                          this.setState(newState)
                        }
                      }}
                    />
                  </div>

                  <div className="col text-center">
                    <label
                      style={{
                        textAlign: "left",
                        marginTop: 5,
                        color: "#000",
                        fontSize: 15
                      }}
                    >
                      XXL
                    </label>
                    <input
                      type="radio"
                      className="form-control"
                      placeholder=""
                      value={"XXL"}
                      checked={this.state.teamData[index].shirtSize === "XXL"}
                      onChange={opt => {
                        if (
                          this.state.teamData[index].editable.toLowerCase() ===
                          "yes"
                        ) {
                          const newStr = {
                            ...this.state.teamData[index],
                            shirtSize: opt.target.value
                          }
                          const newTeamData = {
                            ...this.state.teamData,
                            [index]: newStr
                          }
                          const newState = {
                            ...this.state,
                            teamData: newTeamData
                          }
                          this.setState(newState)
                        }
                      }}
                    />
                  </div>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <label
                    style={{
                      flex: 3,
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 17
                    }}
                  >
                    <strong>Pickup Spot</strong>
                  </label>
                  <select
                    value={this.state.teamData[index].transport}
                    onChange={opt => {
                      if (
                        this.state.teamData[index].editable.toLowerCase() ===
                        "yes"
                      ) {
                        const newStr = {
                          ...this.state.teamData[index],
                          transport: opt.target.value
                        }
                        const newTeamData = {
                          ...this.state.teamData,
                          [index]: newStr
                        }
                        const newState = {
                          ...this.state,
                          teamData: newTeamData
                        }
                        this.setState(newState)
                      }
                    }}
                    style={{ flex: 6 }}
                  >
                    <option style={{ fontSize: 15 }} value={"None"}>
                      None (I have my own transport)
                    </option>
                    <option style={{ fontSize: 15 }} value={"Faraja"}>
                      Faraja
                    </option>
                    <option style={{ fontSize: 15 }} value={"TRM"}>
                      TRM
                    </option>
                    <option style={{ fontSize: 15 }} value={"Ruiru"}>
                      Ruiru
                    </option>
                  </select>
                </div>

                <div style={{ textAlign: "center" }} className="mt-2">
                  <button
                    className="btn badge btn-sm mr-3"
                    style={{ fontSize: 20, background: "red" }}
                    onClick={event => {
                      event.preventDefault()
                      this.handleClick(event, true)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn badge btn-sm"
                    style={{
                      fontSize: 20,
                      background: "lightgreen",
                      cursor:
                        this.state.teamData[index].editable.toLowerCase() ===
                        "yes"
                          ? "pointer"
                          : "no-drop"
                    }}
                    onClick={event => {
                      event.preventDefault()
                      if (
                        this.state.teamData[index].editable.toLowerCase() ===
                        "yes"
                      ) {
                        this.props.toggleTeamMemberEditLoad(true)
                        this.updateSingleTeamMember(
                          {
                            teamId: this.state.user.id,
                            ...this.state.teamData[index],
                            editable: "No"
                          },
                          event,
                          index
                        )
                      } else {
                        this.handleClick(event, true)
                        window.location.reload()
                      }
                    }}
                  >
                    {this.state.teamData[index].editable.toLowerCase() === "no"
                      ? "Reload"
                      : this.props.isTeamMemberEditLoaderShowing
                      ? "Updating"
                      : "Update"}
                    {this.props.isTeamMemberEditLoaderShowing && (
                      <span className="ml-2">
                        <Loader color="#fff" size={28} />
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </Popup>
          </div>
        )
      )
    })
  }

  updateSingleTeamMember = (updatedData, e, ind) => {
    const newTeamData = { ...this.state.teamData[ind], editable: "No" }
    const newState = { ...this.state.teamData, [ind]: newTeamData }
    this.setState({ teamData: newState }, () => {
      fetch(
        `${API_URL}/updateTeamMember?${this.handleFormDataEncode(
          updatedData
        )}`
      )
        .then(res => res.text())
        .then(res => {
          this.handleClick(e, true)
          this.props.toggleTeamMemberEditLoad(false)
          this.setState({
            members: Object.keys(this.state.teamData).map(
              item =>
                `${this.state.teamData[item].firstName} ${this.state.teamData[item].lastName}`
            )
          })
        })
        .catch(err => {
          console.log(err)
          this.props.toggleTeamMemberEditLoad(false)
          this.handleClick(e, true)
        })
    })
  }

  chooseDayToUpdateSheetWith = (day, time) => {
    switch (day) {
      case "Friday, 24th May":
        if (time === "Morning") {
          return "Friday-Morning"
        } else {
          return "Friday-Afternoon"
        }
      case "Saturday, 25th May":
        if (time === "Morning") {
          return "Saturday-Morning"
        } else {
          return "Saturday-Afternoon"
        }
      case "Sunday, 26th May":
        return "Sunday-Full Day"
    }
  }

  updateAllMembersWithNewData = (newData, currentTeam, e) => {
    const {
      teamName,
      organisation,
      targetAmount,
      teamId,
      teamEditable,
      raftingDay,
      raftingTime
    } = newData
    let newTeam = []
    let day = ""
    Object.keys(currentTeam).forEach((item, ind) => {
      if (ind === 1) {
        //first member's unchanged day
        day = this.chooseDayToUpdateSheetWith(
          currentTeam[item].raftingDay,
          currentTeam[item].raftingTime
        )
      }
      newTeam.push({
        ...currentTeam[item],
        teamName,
        organisation,
        targetAmount,
        teamId,
        teamEditable,
        raftingDay,
        raftingTime
      })
    })
    fetch(`${API_URL}/updateSlots?day=${day}&revert=true`)
      .then(response => {
        let newDay = this.chooseDayToUpdateSheetWith(raftingDay, raftingTime)
        fetch(`${API_URL}/updateSlots?day=${newDay}`)
          .then(response => {
          })
          .catch(err => console.log(err))
        if (response.ok) {
        } else {
          console.log("Slots update failed", response.status)
        }
      })
      .catch(err => console.log(err))
    this.setState({ teamData: newTeam }, () => {
      newTeam.forEach(member => {
        fetch(
          `${API_URL}/updateTeamMember?${this.handleFormDataEncode(
            member
          )}`
        )
          .then(res => res.text())
          .then(res => {
            this.handleClick(e, true)
            this.props.toggleTeamEditLoad(false)
          })
          .catch(err => {
            console.log(err)
            this.props.toggleTeamEditLoad(false)
            this.handleClick(e, true)
          })
      })
    })
  }

  handleFormDataEncode = data =>
    Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")

  handleClick = (e, flag) => {
    if (this?.node?.contains(e?.target) && !flag) {
      //click is inside modal
      if (this.state.isModalDisabled) this.setState({ isModalDisabled: false })
      return
    }
    //do whatever i.e. close modal
    if (!this.state.isModalDisabled) this.setState({ isModalDisabled: true })
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  formatShirtSize = size => {
    if (size === "S") return "Small"
    else if (size === "M") return "Medium"
    else if (size === "L") return "Large"
    else if (size === "XL") return "Extra Large"
    else if (size === "XXL") return "Extra Extra Large"
    else return size
  }

  render() {
    let morningIcon = (
      <strong>
        <Icon icon={sun} />
      </strong>
    )
    let afternoonIcon = (
      <strong>
        <Icon icon={cloud} />
      </strong>
    )
    const team = this.state.teamData
    const loggedUser = this.state.user
    const { members } = this.state
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const dashboard = get(this, "props.data.allContentfulBlogPost.edges")[0]
      .node
    if (team) {
      return (
        <Layout>
          <Helmet title={`Team Leader Dashboard | ${siteTitle}`} />
          <PageHero hero={dashboard} />
          <div className="wrapper">
            <Container>
              {/* Uncomment out this section if you have to include the warning test on 1 mode editability */}
              {/* <div style={{ textAlign: "center" }}>
                <p>
                  Amendments can only be made once and thereafter considered
                  final. If additional changes need to be made, please contact
                  Faraja through{" "}
                  <a
                    href="mailto:info@farajacancersupport.org"
                    style={{ textDecoration: "underline", color: "blue" }}
                  >
                    info@farajacancersupport.org
                  </a>
                  .
                </p>
              </div> */}
              <div className="team_lead_card" ref={node => (this.node = node)}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {!this.state.isLoading && (
                    <span
                      style={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        color: "#E4419E",
                        flex: 1,
                        marginBottom: 5
                      }}
                    >
                      Team Leader
                      <span
                        className={`ml-3 badge badge-${
                          team[0].approved.toLowerCase() === "pending"
                            ? "warning"
                            : team[0].approved.toLowerCase() === "no"
                            ? "danger"
                            : "success"
                        }`}
                      >
                        Approval Status -{" "}
                        {team[0].approved.toLowerCase() === "pending"
                          ? "Pending"
                          : team[0].approved.toLowerCase() === "no"
                          ? "Declined"
                          : "Approved"}
                      </span>
                    </span>
                  )}
                  {!this.state.isLoading && (
                    <div className="profile-info mb-2" style={{ flex: 1 }}>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end"
                        }}
                      >
                        <span
                          className="profile_pic"
                          style={{ position: "relative", left: 0 }}
                        ></span>
                        <span className="mr-3">
                          Logged in{loggedUser && ` as ${loggedUser.email}`}{" "}
                        </span>
                        <a
                          onClick={() => netlifyIdentity.open("signup")}
                          style={{
                            color: "#e4419e",
                            cursor: "pointer",
                            textDecoration: "underline"
                          }}
                        >
                          Logout
                        </a>
                      </span>
                    </div>
                  )}
                </div>

                {team && (
                  <div className="teamLeadContent row">
                    <div className="col-12 teamContentWrap">
                      <div className=" row">
                        <div className="col-12 col-md-12">
                          <h2>{team && team[0].teamName}</h2>
                        </div>
                      </div>

                      <div className=" row">
                        <div className="col-12 col-md-3">
                          <p>
                            <strong>
                              <Icon icon={ic_lightbulb_outline} />
                            </strong>{" "}
                            <strong>Organisation:</strong>
                          </p>
                        </div>
                        <div className="col-12 col-md-9">
                          <p>{team && team[0].organisation}</p>
                        </div>
                      </div>

                      <div className=" row">
                        <div className="col-12 col-md-3">
                          <p>
                            <strong>
                              <Icon icon={user} />
                            </strong>{" "}
                            <strong>Team Leader:</strong>
                          </p>
                        </div>
                        <div className="col-12 col-md-9">
                          <p>
                            {team && `${team[0].firstName} ${team[0].lastName}`}
                          </p>
                        </div>
                      </div>

                      <div className=" row">
                        <div className="col-12 col-md-3">
                          <p>
                            <strong>
                              <Icon icon={calendar} />
                            </strong>{" "}
                            <strong>Rafting Day/Time:</strong>
                          </p>
                        </div>
                        <div className="col-12 col-md-9">
                          <p>
                            {team &&
                              `${team[0].raftingDay}, ${team[0].raftingTime}`}
                          </p>
                        </div>
                      </div>

                      <div className=" row">
                        <div className="col-12 col-md-3">
                          <p>
                            <strong>
                              <Icon icon={money} />
                            </strong>{" "}
                            <strong>Target Amount:</strong>
                          </p>
                        </div>
                        <div className="col-12 col-md-9">
                          <p>
                            {team &&
                              `Ksh ${team[0].targetAmount}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                          </p>
                        </div>
                      </div>

                      {!(team[0].approved.toLowerCase() === "yes") ? (
                        <div className="teamList row">
                          <div className="col-12 col-md-3">
                            <p>
                              <strong>
                                <Icon icon={users} />
                              </strong>
                              <strong> Team Members:</strong>
                            </p>
                          </div>
                          <div className="col-12 col-md-9">
                            {this.state.members &&
                            this.state.members.reduce((acc, item) => {
                              if (item.length > 0) acc += 1
                              return acc
                            }, 0) > 0
                              ? this.handleBuildTeamMemberTags()
                              : "No members in your team yet"}
                          </div>
                        </div>
                      ) : (
                        <div className="teamList row">
                          <div className="col-12 col-md-3">
                            <p>
                              <strong>
                                <Icon icon={users} />
                              </strong>
                              <strong> Team Members:</strong>
                            </p>
                          </div>
                          <div className="col-12 col-md-9">
                            {this.state.members && this.state.members.length > 0
                              ? this.handleBuildTeamMemberTagsWithDiscard()
                              : "No members in your team yet"}
                          </div>
                        </div>
                      )}

                      {team[0].approved.toLowerCase() === "yes" && (
                        <Popup
                          trigger={
                            <button
                              title={`${
                                this.state.teamData[0].teamEditable.toLowerCase() ===
                                "yes"
                                  ? "Edit Your Team"
                                  : "Maximum edit limit reached"
                              }`}
                              className={`btn btn-default admin_edit_button ${
                                this.state.teamData[0].teamEditable.toLowerCase() ===
                                "yes"
                                  ? ""
                                  : "disabled"
                              }`}
                              style={{
                                position: "absolute",
                                right: 25,
                                cursor:
                                  this.state.teamData[0].teamEditable.toLowerCase() ===
                                  "yes"
                                    ? "pointer"
                                    : "no-drop"
                              }}
                            >
                              <Icon icon={pencil} />
                            </button>
                          }
                          modal
                          closeOnDocumentClick
                          disabled={
                            this.state.teamData[0].teamEditable.toLowerCase() ===
                            "yes"
                              ? this.state.isModalDisabled
                              : true
                          }
                        >
                          <React.Fragment>
                            <h2 style={{ textAlign: "center" }}>
                              Update Team Info
                            </h2>
                            <form name="teams" className="mt-3" method="POST">
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "row"
                                }}
                              >
                                <label style={{ flex: 3, marginTop: 5 }}>
                                  <strong>Team Name</strong>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="teamName"
                                  maxLength={30}
                                  value={team[0].teamName}
                                  onChange={char => {
                                    const newStr = {
                                      ...this.state.teamData[0],
                                      teamName: char.target.value
                                    }
                                    const newTeamData = {
                                      ...this.state.teamData,
                                      [0]: newStr
                                    }
                                    const newState = {
                                      ...this.state,
                                      teamData: newTeamData
                                    }
                                    this.setState(newState)
                                  }}
                                  style={{ flex: 6 }}
                                ></input>
                              </div>

                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "row"
                                }}
                              >
                                <label style={{ flex: 3, marginTop: 5 }}>
                                  <strong>Organisation</strong>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="organisation"
                                  maxLength={30}
                                  value={team[0].organisation}
                                  onChange={char => {
                                    const newStr = {
                                      ...this.state.teamData[0],
                                      organisation: char.target.value
                                    }
                                    const newTeamData = {
                                      ...this.state.teamData,
                                      [0]: newStr
                                    }
                                    const newState = {
                                      ...this.state,
                                      teamData: newTeamData
                                    }
                                    this.setState(newState)
                                  }}
                                  style={{ flex: 6 }}
                                ></input>
                              </div>

                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "row"
                                }}
                              >
                                <label style={{ flex: 3, marginTop: 5 }}>
                                  <strong>Target Amount (Ksh)</strong>
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  name="targetAmount"
                                  value={team[0].targetAmount}
                                  onChange={num => {
                                    const newStr = {
                                      ...this.state.teamData[0],
                                      targetAmount: num.target.value
                                    }
                                    const newTeamData = {
                                      ...this.state.teamData,
                                      [0]: newStr
                                    }
                                    const newState = {
                                      ...this.state,
                                      teamData: newTeamData
                                    }
                                    this.setState(newState)
                                  }}
                                  style={{ flex: 6 }}
                                ></input>
                              </div>

                              <div className={"row"}>
                                <div className={"col-12"}>
                                  <label>
                                    <strong>Rafting day/time</strong>
                                  </label>
                                </div>
                              </div>

                              {/* Slot 1 */}
                              <div className={"row"} style={{ display: "none" }}>
                                <div
                                  className={`${styles.rafting_day} col-12 col-md-4 col-sm-12`}
                                >
                                  <h3>24th May,
                                    <br/>
                                    (Friday) 
                                  </h3>
                                </div>
                                <div
                                      className={`${styles.rafting_time} ${this
                                        .state.slots.friday < 1 &&
                                        styles.disabled} ${team[0]
                                        .raftingDay === "Friday, 24th May" &&
                                        team[0].raftingTime === "Full Day" &&
                                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                                    >
                                      <a
                                        onClick={() => {
                                          if (
                                            this.state.slots.friday > 0
                                          ) {
                                            const newDate = {
                                              ...this.state.teamData[0],
                                              raftingDay: "Friday, 24th May",
                                              raftingTime: "Full Day"
                                            }
                                            const newTeamData = {
                                              ...this.state.teamData,
                                              [0]: newDate
                                            }
                                            const newState = {
                                              ...this.state,
                                              teamData: newTeamData
                                            }
                                            this.setState(newState)
                                          }
                                        }}
                                      >
                                        Full day
                                      </a>
                                    </div>
                              </div>

                              {/* Slots for Friday Morning and Afternoon */}
                              <div className={"row"}>
                                    <div
                                      className={`${styles.rafting_day} col-12 col-md-4 col-sm-12`}
                                    >
                                      <h3>7th June, (Friday) </h3>
                                    </div>
                                    <div
                                      className={`${styles.rafting_time} ${this
                                        .state.slots.fridayMorning < 1 &&
                                        styles.disabled} ${team[0]
                                        .raftingDay === "Friday, 25th May" &&
                                        team[0].raftingTime === "Morning" &&
                                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                                    >
                                      <a
                                        onClick={() => {
                                          if (
                                            this.state.slots.fridayMorning > 0
                                          ) {
                                            const newDate = {
                                              ...this.state.teamData[0],
                                              raftingDay: "Friday, 25th May",
                                              raftingTime: "Morning"
                                            }
                                            const newTeamData = {
                                              ...this.state.teamData,
                                              [0]: newDate
                                            }
                                            const newState = {
                                              ...this.state,
                                              teamData: newTeamData
                                            }
                                            this.setState(newState)
                                          }
                                        }}
                                      >
                                        Morning
                                      </a>
                                    </div>
                                    <div
                                      className={`${styles.rafting_time} ${this
                                        .state.slots.fridayAfternoon < 1 &&
                                        styles.disabled} ${team[0]
                                        .raftingDay === "Friday, 25th May" &&
                                        team[0].raftingTime === "Afternoon" &&
                                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                                    >
                                      <a
                                        onClick={() => {
                                          if (
                                            this.state.slots.fridayAfternoon >
                                            0
                                          ) {
                                            const newDate = {
                                              ...this.state.teamData[0],
                                              raftingDay: "Friday, 25th May",
                                              raftingTime: "Afternoon"
                                            }
                                            const newTeamData = {
                                              ...this.state.teamData,
                                              [0]: newDate
                                            }
                                            const newState = {
                                              ...this.state,
                                              teamData: newTeamData
                                            }
                                            this.setState(newState)
                                          }
                                        }}
                                      >
                                        Afternoon
                                      </a>
                                    </div>
                                </div>

                                {/* Slot 2 For Saturday */}
                                <div className={"row"}>
                                    <div
                                      className={`${styles.rafting_day} col-12 col-md-4 col-sm-12`}
                                    >
                                      <h3>8th June, (Saturday) </h3>
                                    </div>
                                    <div
                                      className={`${styles.rafting_time} ${this
                                        .state.slots.saturdayMorning < 1 &&
                                        styles.disabled} ${team[0]
                                        .raftingDay === "Saturday, 25th May" &&
                                        team[0].raftingTime === "Morning" &&
                                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                                    >
                                      <a
                                        onClick={() => {
                                          if (
                                            this.state.slots.saturdayMorning > 0
                                          ) {
                                            const newDate = {
                                              ...this.state.teamData[0],
                                              raftingDay: "Saturday, 25th May",
                                              raftingTime: "Morning"
                                            }
                                            const newTeamData = {
                                              ...this.state.teamData,
                                              [0]: newDate
                                            }
                                            const newState = {
                                              ...this.state,
                                              teamData: newTeamData
                                            }
                                            this.setState(newState)
                                          }
                                        }}
                                      >
                                        Morning
                                      </a>
                                    </div>
                                    <div
                                      className={`${styles.rafting_time} ${this
                                        .state.slots.saturdayAfternoon < 1 &&
                                        styles.disabled} ${team[0]
                                        .raftingDay === "Saturday, 25th May" &&
                                        team[0].raftingTime === "Afternoon" &&
                                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                                    >
                                      <a
                                        onClick={() => {
                                          if (
                                            this.state.slots.saturdayAfternoon >
                                            0
                                          ) {
                                            const newDate = {
                                              ...this.state.teamData[0],
                                              raftingDay: "Saturday, 25th May",
                                              raftingTime: "Afternoon"
                                            }
                                            const newTeamData = {
                                              ...this.state.teamData,
                                              [0]: newDate
                                            }
                                            const newState = {
                                              ...this.state,
                                              teamData: newTeamData
                                            }
                                            this.setState(newState)
                                          }
                                        }}
                                      >
                                        Afternoon
                                      </a>
                                    </div>
                                </div>

                                                              {/* Slot 1 */}
                              <div className={"row"} style={{ display: "none" }}>
                                <div
                                  className={`${styles.rafting_day} col-12 col-md-4 col-sm-12`}
                                >
                                  <h3>26th May,
                                    <br/>
                                    (Sunday) 
                                  </h3>
                                </div>
                                <div
                                      className={`${styles.rafting_time} ${this
                                        .state.slots.sunday < 1 &&
                                        styles.disabled} ${team[0]
                                        .raftingDay === "Sunday, 26th May" &&
                                        team[0].raftingTime === "Full Day" &&
                                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                                    >
                                      <a
                                        onClick={() => {
                                          if (
                                            this.state.slots.sunday > 0
                                          ) {
                                            const newDate = {
                                              ...this.state.teamData[0],
                                              raftingDay: "Sunday, 26th May",
                                              raftingTime: "Full Day"
                                            }
                                            const newTeamData = {
                                              ...this.state.teamData,
                                              [0]: newDate
                                            }
                                            const newState = {
                                              ...this.state,
                                              teamData: newTeamData
                                            }
                                            this.setState(newState)
                                          }
                                        }}
                                      >
                                        Full day
                                      </a>
                                    </div>
                              </div>

                              <div
                                style={{ textAlign: "center" }}
                                className="mt-2"
                              >
                                <button
                                  className="btn badge btn-sm mr-3"
                                  style={{ fontSize: 20, background: "red" }}
                                  onClick={event => {
                                    event.preventDefault()
                                    this.handleClick(event, true)
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn badge btn-sm"
                                  style={{
                                    fontSize: 20,
                                    background: "mediumseagreen"
                                  }}
                                  onClick={event => {
                                    event.preventDefault()
                                    if (
                                      this.state.teamData[0].teamEditable.toLowerCase() ===
                                      "yes"
                                    ) {
                                      this.props.toggleTeamEditLoad(true)
                                      this.updateAllMembersWithNewData(
                                        {
                                          teamId: this.state.user.id,
                                          ...this.state.teamData[0],
                                          // Change State to "No" if you want Team Leader to edit once
                                          teamEditable: "Yes"
                                        },
                                        this.state.teamData,
                                        event
                                      )
                                    }
                                  }}
                                >
                                  {this.props.isLoaderShowing
                                    ? "Saving"
                                    : "Save"}
                                  {this.props.isLoaderShowing && (
                                    <span className="ml-2">
                                      <Loader color="#fff" size={28} />
                                    </span>
                                  )}
                                </button>
                              </div>
                            </form>
                          </React.Fragment>
                        </Popup>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </div>
        </Layout>
      )
    } else {
      return <React.Fragment></React.Fragment>
    }
  }
}

const mapStateToProps = state => ({
  isLoaderShowing: state.isTeamEditLoaderShowing,
  isTeamMemberEditLoaderShowing: state.isTeamMemberEditLoaderShowing
})

const mapDispatchToProps = () => {
  return {
    toggleTeamEditLoad,
    toggleTeamMemberEditLoad
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(TeamPage)

export const pageQuery = graphql`
  query teamDashboardQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(
      filter: { tags: { eq: "Register" } }
      sort: { fields: [sort], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          heroImage {
            sizes(maxWidth: 1024, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }

          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
